<template>
    <section class="">
        <front-table :front_page="page"
                     :front_records="records"
                     :front_search="search"
                     :front_header="header"
                     :model="model"
                     @reload="handleReload"
                     @edit="handleEdit"
                     btn_copy
                     @copy="handleCopy"

        >
            <div class="pull-right">
                <el-button size="medium" type="warning" class="mr-2" @click="jumpUpload"
                           icon="el-icon-upload" v-if="user.role_level > 2">
                    {{('导入教师')}}
                </el-button>
            </div>
        </front-table>
        <!--编辑界面-->
        <edit-dialog :edit_mode="edit_mode" :model="model" :single="single" :fields="fields" dual
                     @success="handleSuccess" @cancel="handleCancel"></edit-dialog>

        <div class="clearfix"></div>
    </section>
</template>

<script>
    import EditDialog from "../components/edit-dialog";
    import FrontTable from "../components/front-table";
    import FrontMixins from "../common/mixins/front-mixins";
    import EchoMixin from "../common/mixins/echo-mixin"
    import {mapState} from "vuex";

    export default {
        mixins: [FrontMixins,EchoMixin],
        name: 'admin-room',
        components: {FrontTable, EditDialog},
        data() {
            return {
                batch: false,
                payload: {},
                rooms: {},
            }
        },
        created() {
            this.model = 'Admin.Teacher';
        },
        computed:{
          ...mapState(['user']),
        },
        mounted() {
        },
        methods: {
            jumpUpload(){
                this.$router.push("/admin/import-teacher");
            }
        }
    }

</script>

<style lang="scss">

</style>
