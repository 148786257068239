<template>
    <section class="">
        <front-table :front_page="page"
                     :front_records="records"
                     :front_search="search"
                     :front_header="header"
                     :model="model"
                     btn_review
                     @review="handleReview"
                     @reload="handleReload"
                     @edit="handleEdit"
                     delete_warning="请注意!删除操作将会删除所有学期的整个课程，所有相关信息包括录像等都会被删除。如果本学期没有此课程，请清除排课即可。"
        >
            <div class="pull-right" style="margin-top:4px; ">
                <el-cascader
                    class="mr-3"
                    v-model="search.area"
                    placeholder="请选择地区"
                    size="medium"
                    :options="areas"
                    :props="{checkStrictly:true}"
                    @change="handleAreaChange"
                    style="width: 300px;"
                    clearable
                    filterable>
                </el-cascader>
            </div>
            <div class="pull-right" style="margin-right:10px;margin-top: 4px; ">
                <el-select v-if="search.extra"
                    clearable filterable size="medium"
                    v-model="search.extra['S']"
                           @change="handleChangeSemesterForExtra"
                           placeholder="请选择学期" >
                    <el-option v-for="(x,id) in semester_options" :key="id" :value="id" :label="x.name+' ('+x.start_date+' - '+x.end_date+')'">
                        <span class="d-inline-block" style="width:200px;">{{ x.name }}</span>
                        <span class="text-success">{{ x.start_date }} - {{ x.end_date }}</span>
                    </el-option>
                </el-select>
            </div>
        </front-table>
        <lesson-view :single="single" :view_mode.sync="view_mode"></lesson-view>
        <div class="clearfix"></div>
    </section>
</template>

<script>
import FrontTable from "../components/front-table";
import FrontMixins from "../common/mixins/front-mixins";
import LessonView from "@/components/lesson-view";
import SemesterMixin from "@/views/mixins/semester.mixin";

export default {
    mixins: [FrontMixins,SemesterMixin],
    name: 'admin-course',
    components: {FrontTable, LessonView},
    data() {
        return {
            view_mode: false,
            use_cache:true,
        }
    },
    created() {
        this.model = 'Admin.' + this.$route.meta.model;
        this.loadArea(2,true);
    },
    methods: {
        afterFirstReload(){
            this.loadSemesterListForExtra();
        },
        jumpUpload() {
            this.$router.push('/admin/import-course');
        },
        lockMeeting() {
            this.$api(this.model + '.Lock', this.single, 1).then(() => {
                this.$notify.success('会议已经结束，并锁定!');
            })
        },
        unlockMeeting() {
            this.$api(this.model + '.Unlock', this.single, 1).then(() => {
                this.$notify.success('会议已经开放!');
            })
        },
        downloadReport() {
            window.open(this.$apiUrl('Admin.Report.Course.Arrange'));
        },
        handleEdit(id) {
            this.$router.push("/admin/lesson/edit/" + id);
        },
    },

}

</script>

<style lang="scss">

</style>
